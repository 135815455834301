import img from '../../statics/images/unirse.jpeg';

export default function JoinTeam() {
  return (
    <div className='bg-gray-100 py-14 lg:py-28'>
      <div
        className='container flex flex-col px-6 mx-auto space-y-6 lg:flex-row lg:items-center gap-8'
        id='unirse-al-equipo'
      >
        <div className='w-full lg:w-1/2'>
          <div>
            <h2 className='text-2xl font-bold text-gray-800 md:text-4xl'>Unirse al equipo</h2>
            <p className='mt-4 text-gray-600'>
              Actualmente estamos aceptando solicitudes para conductores de Clase A.
            </p>
            <div className='grid gap-6 mt-8 sm:grid-cols-2'>
              <div className='flex items-center text-gray-800 -px-3'>
                <svg
                  className='w-5 h-5 mx-3'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M5 13l4 4L19 7'
                  />
                </svg>

                <span className='mx-3 flex-1'>Tener 1 año de experiencia (comprobable)</span>
              </div>

              <div className='flex items-center text-gray-800 -px-3'>
                <svg
                  className='w-5 h-5 mx-3'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M5 13l4 4L19 7'
                  />
                </svg>

                <span className='mx-3 flex-1'>
                  Ser capaz de pasar la prueba de drogas y el examen físico.
                </span>
              </div>

              <div className='flex items-center text-gray-800 -px-3'>
                <svg
                  className='w-5 h-5 mx-3'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M5 13l4 4L19 7'
                  />
                </svg>

                <span className='mx-3 flex-1'>
                  Tener al menos 21 años de edad (23 años de edad para conductores fuera del estado)
                </span>
              </div>

              <div className='flex items-center text-gray-800 -px-3'>
                <svg
                  className='w-5 h-5 mx-3'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M5 13l4 4L19 7'
                  />
                </svg>

                <span className='mx-3 flex-1'>
                  No más de 3 puntos en su historial de manejo durante los últimos 36 meses
                </span>
              </div>

              <div className='flex items-center text-gray-800 -px-3'>
                <svg
                  className='w-5 h-5 mx-3'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M5 13l4 4L19 7'
                  />
                </svg>

                <span className='mx-3 flex-1'>
                  Estar dispuesto a trabajar en todos los turnos, incluidos los fines de semana, las
                  escalas durante la noche y los días festivos.
                </span>
              </div>

              <div className='flex items-center text-gray-800 -px-3'>
                <svg
                  className='w-5 h-5 mx-3'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M5 13l4 4L19 7'
                  />
                </svg>

                <span className='mx-3 flex-1'>
                  No tener una citación relacionada con drogas o alcohol, manejo imprudente o
                  atropello y fuga en el registro de manejo en los últimos 7 años
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className='flex items-center justify-center w-full h-96 lg:w-1/2'>
          <img className='object-cover w-full h-full max-w-2xl rounded-md' src={img} alt='dreyko' />
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { Navbar, Footer, Contact, Team, TrustedBy, Work, JoinTeam } from './components';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import slide2 from './statics/images/slide2.png';
import slide3 from './statics/images/slide3.jpeg';
import quienesSomos from './statics/images/quienes_somos.jpeg';
import mision from './statics/images/mision.jpeg';
import rutas from './statics/images/rutas.png';
import './App.css';

const slides = [
  {
    image:
      'https://www.technicolor.com/sites/default/files/pictures/Distribute/Supply%20Chain%20Solutions/TM_TRUCK.jpg',
    text: 'Tu mejor opción en lógistica transportes',
    highlight: 'Dreyko',
    action: 'CONTACTANOS',
    link: '',
  },
  {
    image: slide2,
    text: 'Rutas por todo el país',
    action: 'SOLICITAR COTIZACIÓN',
    link: '',
  },
  {
    image: slide3,
    text: 'FORMA PARTE DE NUESTRO EQUIPO',
    action: 'CONTACTANOS',
    link: '',
  },
];

function App() {
  return (
    <React.Fragment>
      <Navbar />
      <header>
        <Splide
          aria-label='hero'
          options={{
            height: 'calc(100vh - 68px)',
            autoplay: true,
            type: 'loop',
            pauseOnHover: false,
          }}
        >
          {slides.map(slide => (
            <SplideSlide key={slide.image}>
              <div
                className='w-full bg-center bg-cover h-full bg-no-repeat'
                style={{
                  backgroundImage: `url('${slide.image}')`,
                }}
              >
                <div className='flex items-center justify-start w-full h-full bg-gray-900 bg-opacity-50'>
                  <div className='w-full lg:w-1/2 px-16'>
                    <h1 className='text-3xl font-bold tracking-wide text-white uppercase lg:text-5xl lg:leading-tight'>
                      {slide.text}
                      {slide.highlight && (
                        <span className='text-accent-900'> {slide.highlight}</span>
                      )}
                    </h1>
                    <a href='#contact'>
                      <button className='w-full px-8 py-4 mt-12 text-md font-medium text-white uppercase transition-colors duration-200 transform bg-primary rounded-md md:w-auto hover:bg-gray-600 focus:outline-none focus:bg-blue-500'>
                        {slide.action}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </header>

      <section id='aboutus' className='bg-gray-100 lg:py-28 lg:flex lg:justify-center'>
        <div className='bg-white lg:mx-8 flex flex-col-reverse lg:flex-row lg:max-w-5xl lg:shadow-lg lg:rounded-lg'>
          <div className='lg:w-1/2'>
            <div
              className='h-64 bg-cover lg:rounded-lg lg:h-full'
              style={{
                backgroundImage: `url('${quienesSomos}')`,
              }}
            ></div>
          </div>

          <div className='max-w-xl px-6 py-14 lg:max-w-5xl lg:w-1/2'>
            <h2 className='text-2xl font-bold text-gray-800 md:text-3xl'>
              Quienes <span className='text-accent-900'>somos</span>
            </h2>
            <p className='mt-4 text-gray-600'>
              Dreyko ha brindado servicios de transporte terrestre durante más de 10 años. Nuestro
              enfoque disciplinado para garantizar un servicio puntual y de alta calidad nos ha
              convertido en un socio confiable para nuestros clientes. Las mejoras constantes en los
              planes de operación nos ayudan a estar preparados para lo inesperado y brindar una
              calidad excepcional en todo momento.
            </p>
          </div>
        </div>
      </section>

      <TrustedBy />

      <section id='aboutus' className='bg-gray-100 lg:py-28 lg:flex lg:justify-center'>
        <div className='bg-white lg:mx-8 flex flex-col-reverse lg:flex-row lg:max-w-5xl lg:shadow-lg lg:rounded-lg'>
          <div className='lg:w-1/2'>
            <div
              className='h-64 bg-cover bg-bottom lg:rounded-lg lg:h-full'
              style={{
                backgroundImage: `url('${mision}')`,
              }}
            ></div>
          </div>

          <div className='max-w-xl px-6 py-12 lg:max-w-5xl lg:w-1/2'>
            <h2 className='text-2xl font-bold text-gray-800 md:text-3xl'>
              Nuestra <span className='text-accent-900'>Misión</span>
            </h2>
            <p className='mt-4 text-gray-600'>
              Adreyco es una corporación que tiene el firme propósito de lograr la satisfacción de
              nuestros clientes, proporcionándoles servicios especializados de transporte terrestre,
              con altos niveles de calidad y seguridad. Contamos con la capacidad de brindar
              servicios flexibles que sean oportunos y confiables. Estamos comprometidos a mantener
              un proceso sistemático de mejora continua y desarrollo, que de igual manera le
              permitan contribuir al desarrollo sostenido de sus empleados, clientes y sociedad.
            </p>
          </div>
        </div>
      </section>

      <Team />

      <div className='bg-gray-100 py-14 lg:py-28 ' id='rutas'>
        <div className='container mx-auto px-6 lg:flex lg:justify-center flex-col items-center'>
          <h1 className='text-2xl font-bold text-gray-800 md:text-4xl'>
            Nuestras <span className='text-accent-900'>Rutas</span>
          </h1>

          <img className='lg:w-1/2' src={rutas} alt='rutas' />
        </div>
      </div>

      <Work />

      <JoinTeam />

      <section id='contact' className='bg-white py-14 lg:py-28'>
        <Contact />
      </section>

      <Footer />
    </React.Fragment>
  );
}

export default App;

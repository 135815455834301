import img from '../../statics/images/trabaja_con_nosotros.jpeg';

export default function Work() {
  return (
    <section className='bg-white' id='trabaja-con-nosotros'>
      <div className='container px-6 py-14 lg:py-28 mx-auto'>
        <h2 className='text-2xl font-bold text-gray-800 md:text-4xl'>Trabaja con nosotros</h2>

        <div className='mt-2'>
          <span className='inline-block w-40 h-1 bg-accent-900 rounded-full'></span>
          <span className='inline-block w-3 h-1 ml-1 bg-accent-900 rounded-full'></span>
          <span className='inline-block w-1 h-1 ml-1 bg-accent-900 rounded-full'></span>
        </div>

        <div className='lg:flex lg:items-center'>
          <div className='w-full lg:w-1/2 space-y-12'>
            <p className='text-gray-500'>
              Creemos que nuestro éxito está directamente relacionado con el arduo trabajo realizado
              por nuestros dedicados empleados.{' '}
            </p>
            <div className='flex items-start -mx-4'>
              <span className='inline-block p-2 text-accent-900 bg-accent-100 rounded-xl mx-4'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='w-6 h-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z'
                  />
                </svg>
              </span>

              <h2 className='mx-4 text-lg lg:text-2xl font-semibold text-gray-700'>
                Tasa de pago competitiva
              </h2>
            </div>

            <div className='flex items-start -mx-4'>
              <span className='inline-block p-2 text-accent-900 bg-accent-100 rounded-xl mx-4'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='w-6 h-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z'
                  />
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z'
                  />
                </svg>
              </span>

              <h2 className='mx-4 text-lg lg:text-2xl font-semibold text-gray-700'>
                Tractores asignados
              </h2>
            </div>

            <div className='flex items-start -mx-4'>
              <span className='inline-block p-2 text-accent-900 bg-accent-100 rounded-xl mx-4'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='w-6 h-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z'
                  />
                </svg>
              </span>

              <h2 className='mx-4 text-lg lg:text-2xl font-semibold text-gray-700'>
                Orientación pagada
              </h2>
            </div>

            <div className='flex items-start -mx-4'>
              <span className='inline-block p-2 text-accent-900 bg-accent-100 rounded-xl mx-4'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='w-6 h-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z'
                  />
                </svg>
              </span>

              <h2 className='mx-4 text-lg lg:text-2xl font-semibold text-gray-700'>
                Tiempo en casa frecuente
              </h2>
            </div>
            <div>
              <a
                href='#unirse-al-equipo'
                className='px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-primary rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600'
              >
                Unirse al equipo
              </a>
            </div>
          </div>

          <div className='hidden lg:flex lg:w-1/2 lg:justify-center'>
            <img
              className='w-[28rem] h-[28rem] flex-shrink-0 object-cover xl:w-[34rem] xl:h-[34rem] rounded-full'
              src={img}
              alt='dreyko'
            />
          </div>
        </div>
      </div>
    </section>
  );
}

import servicios from '../../statics/images/servicios.jpeg';
import com1 from '../../statics/images/com_1.png';
import com2 from '../../statics/images/com_2.jpeg';
import com3 from '../../statics/images/com_3.png';
import com4 from '../../statics/images/com_4.png';
import com5 from '../../statics/images/com_5.png';
import com6 from '../../statics/images/com_6.png';
import com7 from '../../statics/images/com_7.jpeg';
import com8 from '../../statics/images/com_8.png';
import com9 from '../../statics/images/com_9.png';

const companies = [com1, com2, com3, com4, com5, com6, com7, com8, com9];

export default function Trusted() {
  return (
    <section id='services' className='bg-white'>
      <div className='container px-6 py-14 lg:py-28 mx-auto'>
        <div className='lg:flex lg:items-center'>
          <div className='w-full space-y-12 lg:w-1/2 '>
            <div>
              <h2 className='text-2xl font-bold text-gray-800 md:text-4xl'>
                Conoce nuestros servicios <br /> de transportes
              </h2>

              <div className='mt-2'>
                <span className='inline-block w-40 h-1 rounded-full bg-accent-900'></span>
                <span className='inline-block w-3 h-1 ml-1 rounded-full bg-accent-900'></span>
                <span className='inline-block w-1 h-1 ml-1 rounded-full bg-accent-900'></span>
              </div>
            </div>

            <p className='mt-3 text-gray-500'>
              En <span className='text-accent-900'>DREYKO</span>, contamos con transporte de
              servicio terrestre, que van desde cargas individuales hasta flotas completas.
            </p>

            <div className='flex items-start -mx-4'>
              <span className='inline-block p-2 text-accent-900 bg-accent-100 rounded-xl mx-4'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='w-6 h-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01'
                  />
                </svg>
              </span>

              <div className='mx-4'>
                <h2 className='text-lg lg:text-2xl font-semibold text-gray-700'>
                  Servicio local en el sur de California.
                </h2>
              </div>
            </div>

            <div className='flex items-start -mx-4'>
              <span className='inline-block p-2 text-accent-900 bg-accent-100 rounded-xl mx-4'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='w-6 h-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4'
                  />
                </svg>
              </span>

              <div className='mx-4'>
                <h2 className='text-lg lg:text-2xl font-semibold text-gray-700'>
                  Servicio de larga distancia en todo Estados Unidos de América.
                </h2>
              </div>
            </div>
          </div>

          <div className='hidden lg:flex lg:items-center lg:w-1/2 lg:justify-center'>
            <img
              className='w-[28rem] h-[28rem] object-cover xl:w-[34rem] xl:h-[34rem] rounded-md'
              src={servicios}
              alt='dreyko'
            />
          </div>
        </div>

        <hr className='border-gray-200 my-12' />

        <div className='grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-9 place-content-center'>
          {companies.map((img, index) => (
            <div className='flex justify-center w-full items-center '>
              <img
                className='lg:max-h-16 w-28 lg:w-auto'
                key={img}
                src={img}
                alt={`company-${index}`}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

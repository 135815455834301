export default function Footer() {
  return (
    <footer className='bg-white '>
      <div className='container px-6 pb-8 mx-auto'>
        <hr className='mb-6 border-gray-200 md:mb-10 ' />

        <div className='flex flex-col items-center sm:flex-row sm:justify-between'>
          <div className='flex gap-2 flex-col'>
            <p>Contactanos/Contact us</p>
            <p className='text-sm text-gray-500 '>28114 Missouri Trail, Perris CA 92570</p>
            <p className='text-sm text-gray-500 '>info@dreykotransport.com </p>
            <p className='text-sm text-gray-500 '>(951)583 9526</p>
          </div>

          <p className='text-sm text-gray-500 '>Dreyco © Copyright 2023. All Rights Reserved.</p>
          <div className='flex -mx-2'>
            <a
              href='https://www.instagram.com/dreyko_transport/'
              className='mx-2 text-gray-600 transition-colors duration-300  hover:text-blue-500'
              aria-label='instagram'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='icon icon-tabler icon-tabler-brand-instagram'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                stroke-width='2'
                stroke='currentColor'
                fill='none'
                stroke-linecap='round'
                stroke-linejoin='round'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none'></path>
                <path d='M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z'></path>
                <path d='M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0'></path>
                <path d='M16.5 7.5l0 .01'></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

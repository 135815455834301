import team from '../../statics/images/equipo.jpeg';

const points = [
  'Contamos con diversas cajas secas con suspensión neumática de 53 pies con puertas batientes.',
  'Flota de camiones con literas.',
  'Cada unidad cuenta con sistema de localizador y comunicación satelital las 24 horas del día.',
];

export default function Team() {
  return (
    <div className='container flex gap-8 flex-col px-6 py-14 lg:py-28 mx-auto lg:flex-row lg:items-center'>
      <div className='w-full lg:w-1/2'>
        <div className='lg:max-w-lg'>
          <h1 className='text-2xl font-bold text-gray-800 md:text-4xl'>Nuestro Equipo</h1>

          <div className='grid gap-6 mt-8 sm:grid-cols-2 lg:grid-cols-1'>
            {points.map(point => (
              <div className='flex items-center text-gray-800 '>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  class='w-6 h-6 mx-2 text-accent-900'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z'
                  />
                </svg>

                <span className='mx-3 flex-1'>{point}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='flex items-center justify-center w-full h-96 lg:w-1/2'>
        <img
          className='object-cover w-full h-full max-w-2xl rounded-md object-bottom'
          src={team}
          alt='imagen de equipo'
        />
      </div>
    </div>
  );
}

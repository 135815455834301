import { useState } from 'react';
import LogoHorizontal from '../../statics/images/logo-horizontal.png';

export default function Navbar() {
  const [show, setShow] = useState(false);
  // const [showLocale, setShowLocale] = useState(false);
  const toggleMenu = () => setShow(prev => !prev);
  // const toggleLocale = () => setShowLocale(prev => !prev);
  return (
    <nav className='bg-white shadow'>
      <div className='container px-6 py-4 mx-auto'>
        <div className='lg:flex lg:items-center lg:justify-between'>
          <div className='flex items-center justify-between'>
            <div className='text-xl font-semibold text-gray-700'>
              <a
                className='text-2xl font-bold text-gray-800 lg:text-3xl hover:text-gray-700 h-full'
                href='/'
              >
                <div
                  className='h-9 w-52 bg-cover bg-center'
                  style={{ backgroundImage: `url('${LogoHorizontal}')` }}
                ></div>
              </a>
            </div>

            <div className='relative inline-block lg:hidden'>
              {/* <!-- Dropdown toggle button --> */}
              <button
                onClick={toggleMenu}
                className='relative z-10 block p-2 text-gray-700 bg-white border border-transparent rounded-md focus:border-blue-500 focus:ring-opacity-40 focus:ring-blue-300 focus:ring focus:outline-none'
              >
                <svg viewBox='0 0 24 24' className='w-6 h-6 fill-current'>
                  <path
                    fillRule='evenodd'
                    d='M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z'
                  ></path>
                </svg>
              </button>

              {/* <!-- Dropdown menu --> */}
              <div
                className={`absolute right-0 z-20 w-48 py-2 mt-2 bg-white rounded-md shadow-xl ${
                  show ? 'visible opacity-100' : 'invisible opacity-0'
                }`}
              >
                <a
                  href='#aboutus'
                  className='block px-4 py-3 text-sm text-gray-600 transition-colors duration-200 transform hover:bg-gray-100'
                >
                  Nosotros
                </a>
                <a
                  href='#services'
                  className='block px-4 py-3 text-sm text-gray-600 transition-colors duration-200 transform hover:bg-gray-100'
                >
                  Servicios
                </a>
                <a
                  href='#rutas'
                  className='block px-4 py-3 text-sm text-gray-600 transition-colors duration-200 transform hover:bg-gray-100'
                >
                  Rutas
                </a>
                <a
                  href='#trabaja-con-nosotros'
                  className='block px-4 py-3 text-sm text-gray-600 transition-colors duration-200 transform hover:bg-gray-100'
                >
                  Trabaja con Nosotros
                </a>
                <a
                  href='#contact'
                  className='block px-4 py-3 text-sm text-gray-600 transition-colors duration-200 transform hover:bg-gray-100'
                >
                  Contáctanos
                </a>
              </div>
            </div>
          </div>

          <div className='-mx-4 hidden lg:flex lg:items-center'>
            <a
              href='#aboutus'
              className='block mx-4 mt-2 text-md text-gray-700 lg:mt-0 hover:text-primary'
            >
              Nosotros
            </a>
            <a
              href='#services'
              className='block mx-4 mt-2 text-md text-gray-700 lg:mt-0 hover:text-primary'
            >
              Servicios
            </a>
            <a
              href='#rutas'
              className='block mx-4 mt-2 text-md text-gray-700 lg:mt-0 hover:text-primary'
            >
              Rutas
            </a>
            <a
              href='#trabaja-con-nosotros'
              className='block mx-4 mt-2 text-md text-gray-700 lg:mt-0 hover:text-primary'
            >
              Trabaja con Nosotros
            </a>
            <a
              href='#contact'
              className='block mx-4 mt-2 text-md text-gray-700 lg:mt-0 hover:text-primary'
            >
              Contáctanos
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

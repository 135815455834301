export default function Contact() {
  return (
    <section className='max-w-4xl p-6 mx-auto bg-white rounded-md lg:shadow-lg'>
      <h2 className='text-2xl font-bold text-gray-800 md:text-3xl'>Contáctanos</h2>

      <form>
        <div className='grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2'>
          <div>
            <label className='text-gray-700' htmlFor='name'>
              Nombre
            </label>
            <input
              id='name'
              type='text'
              className='block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring'
            />
          </div>

          <div>
            <label className='text-gray-700' htmlFor='lastname'>
              Apellido
            </label>
            <input
              id='lastname'
              type='text'
              className='block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring'
            />
          </div>

          <div>
            <label className='text-gray-700' htmlFor='emailAddress'>
              Correo
            </label>
            <input
              id='emailAddress'
              type='email'
              className='block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring'
            />
          </div>

          <div>
            <label className='text-gray-700' htmlFor='tel'>
              Télefono
            </label>
            <input
              id='tel'
              type='tel'
              className='block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring'
            />
          </div>
        </div>

        <div className='flex justify-end mt-6'>
          <button className='px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-primary rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600'>
            Enviar
          </button>
        </div>
      </form>
    </section>
  );
}
